import {
    mdiAccountVoice, mdiAirplaneTakeoff, mdiCalendarMonthOutline, mdiCloudDownload, mdiFax, mdiHeadLightbulbOutline, mdiNewspaperVariantMultipleOutline,
    mdiPencilBoxMultipleOutline, mdiPill, mdiPrescription
} from '@mdi/js'

const formTypes = [
  {
    id: 0,
    value: 'renewingRx',
    title: 'Renewing Prescription',
    icon: mdiNewspaperVariantMultipleOutline,
  },
  {
    id: 1,
    value: 'adaptingRx',
    title: 'Adapting Prescription',
    icon: mdiPencilBoxMultipleOutline,
  },
  {
    id: 2,
    value: 'pharmaOpinion',
    title: 'Pharmaceutical Opinion',
    icon: mdiHeadLightbulbOutline,
  },
  {
    id: 3,
    value: 'vacationSupply',
    title: 'Vacation Supply',
    icon: mdiAirplaneTakeoff,
  },
  {
    id: 4,
    value: 'fentanyl',
    title: 'Fentanyl',
    icon: mdiPrescription,
  },
  {
    id: 5,
    value: 'verbalRx',
    title: 'Verbal Prescription',
    icon: mdiAccountVoice,
  },
  {
    id: 6,
    value: 'blisterPackGrid',
    title: 'Blister Pack Grid',
    icon: mdiPill,
  },
  {
    id: 7,
    value: 'frequentDispensingAssessment',
    title: 'Freq. Dispensing Assessment',
    icon: mdiCalendarMonthOutline,
  },
  {
    id: 8,
    value: 'letterTemplate',
    title: 'Letter Template',
    icon: mdiFax,
  },
  {
    id: 9,
    value: 'rapidAnitgen',
    title: 'Rapid Antigen',
    icon: mdiCloudDownload,
  },
]

export default formTypes
