<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Document Dashboard:  {{ formTypes[formType].title }} <v-icon>{{ formTypes[formType].icon }}</v-icon>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="triggerRefresh"
        >
          <v-icon size="20">
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="formType"
            :prepend-icon="icon"
            placeholder="Select Form Type"
            :items="formTypes"
            item-text="title"
            item-value="id"
            outlined
            clearable
            dense
            hide-details
            @change="getForms(formType)"
          ></v-select>
        </v-col>

        <!-- Place Holder -->
        <v-col
          cols="12"
          sm="4"
        >
        </v-col>

        <!-- Search Bar -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="searchQuery"
            :append-icon="icons.mdiMagnify"
            placeholder="Search"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- table -->
      <v-data-table
        v-model="selectedDocs"
        :search="searchQuery"
        :headers="documentDashboardHeaders"
        :items="documentsList"
        :loading="showOverlay"
        single-select
        item-key="_id"
      >
        <template
          v-for="header in documentDashboardHeaders.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template #[`item.dlPDF`]="{ item, index }">
          <v-icon
            color="primary"
            medium
            class="me-3"
            @click="downloadPdf(item)"
          >
            {{ icons.mdiCloudDownload }}
          </v-icon>
          <p class="tw-text-gray-600"></p>
        </template>
        <template v-slot:no-data>
          <p class="tw-mt-5">
            No matching records found
          </p>
          <v-btn
            color="success"
            class="tw-mb-3"
            @click="triggerRefresh"
          >
            Refresh
          </v-btn>
        </template>
        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            color="primary"
            outlined
            pill
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template
          #item.fullName="{ item }"
        >
          <span>{{ item.state.patientlastname || item.state['LAST NAME'] }}, {{ item.state.patientfirstname || item.state['FIRST NAME'] }} </span>
        </template>
        <template
          #item.dob="{ item }"
        >
          <span>{{ item.state.dob || item.state['DATE OF BIRTH'] }}</span>
        </template>
      </v-data-table>
      <v-overlay
        v-model="showOverlay"
        absolute
        color="white"
        opacity="0.75"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiRefresh,
  mdiMagnify,
  mdiCloudDownload,
  mdiNewspaperVariantMultipleOutline,
  mdiPencilBoxMultipleOutline,
  mdiHeadLightbulbOutline,
  mdiAirplaneTakeoff,
  mdiPrescription,
  mdiAccountVoice,
  mdiPill,
  mdiCalendarMonthOutline,
  mdiFax,
} from '@mdi/js'
import { formTypes } from '@/assets/lists/index'
import { documentDashboardHeaders } from '@/assets/tableHeaders/index'
import { stateService } from '@/render/api/zarya'

export default {
  name: 'DocumentDashboard',
  components: {},
  setup() {
    const icon = ref(mdiNewspaperVariantMultipleOutline)
    const selectedDocs = ref([])
    const documentsList = ref([])
    const searchQuery = ref('')
    const formType = ref(0)
    const showOverlay = ref(false)
    const refreshData = [
      {
        title: 'Refresh',
      },
    ]

    return {
      selectedDocs,
      documentsList,
      searchQuery,
      documentDashboardHeaders,
      refreshData,
      formTypes,
      showOverlay,
      formType,
      icon,
      icons: {
        mdiRefresh,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiMagnify,
        mdiCloudDownload,
        mdiNewspaperVariantMultipleOutline,
        mdiPencilBoxMultipleOutline,
        mdiHeadLightbulbOutline,
        mdiAirplaneTakeoff,
        mdiPrescription,
        mdiAccountVoice,
        mdiPill,
        mdiCalendarMonthOutline,
        mdiFax,
      },
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to !== from && to.query.tab) {
          this.formType = to.query.tab
          this.icon = this.formTypes[to.query.tab].icon
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.triggerRefresh()
  },
  methods: {
    async getForms() {
      this.icon = this.formTypes[this.formType].icon
      const token = await this.$auth.getTokenSilently()
      this.pharmacyId = await this.$store.state.User.pharmacy._id
      this.documentsList = await stateService.getStateByFormType(
        this.formTypes[this.formType].value,
        this.pharmacyId,
        token,
      )
    },
    async triggerRefresh() {
      this.showOverlay = true
      await this.getForms()
      this.showOverlay = false
    },
    async downloadPdf(pdfRef) {
      try {
        const token = await this.$auth.getTokenSilently()
        await this.$store.dispatch('pdfDownload', {
          token,
          pdfRef: pdfRef.state.pdfRef,
        })
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>
