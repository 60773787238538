var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Document Dashboard: "+_vm._s(_vm.formTypes[_vm.formType].title)+" "),_c('v-icon',[_vm._v(_vm._s(_vm.formTypes[_vm.formType].icon))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.triggerRefresh}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)],1),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"prepend-icon":_vm.icon,"placeholder":"Select Form Type","items":_vm.formTypes,"item-text":"title","item-value":"id","outlined":"","clearable":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.getForms(_vm.formType)}},model:{value:(_vm.formType),callback:function ($$v) {_vm.formType=$$v},expression:"formType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-data-table',{attrs:{"search":_vm.searchQuery,"headers":_vm.documentDashboardHeaders,"items":_vm.documentsList,"loading":_vm.showOverlay,"single-select":"","item-key":"_id"},scopedSlots:_vm._u([_vm._l((_vm.documentDashboardHeaders.filter(function (header) { return header.hasOwnProperty('formatter'); }
        )),function(header){return {key:("item." + (header.value)),fn:function(ref){
        var header = ref.header;
        var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.dlPDF",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"primary","medium":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudDownload)+" ")]),_c('p',{staticClass:"tw-text-gray-600"})]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"tw-mt-5"},[_vm._v(" No matching records found ")]),_c('v-btn',{staticClass:"tw-mb-3",attrs:{"color":"success"},on:{"click":_vm.triggerRefresh}},[_vm._v(" Refresh ")])]},proxy:true},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":"","pill":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.fullName",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.state.patientlastname || item.state['LAST NAME'])+", "+_vm._s(item.state.patientfirstname || item.state['FIRST NAME'])+" ")])]}},{key:"item.dob",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.state.dob || item.state['DATE OF BIRTH']))])]}}],null,true),model:{value:(_vm.selectedDocs),callback:function ($$v) {_vm.selectedDocs=$$v},expression:"selectedDocs"}}),_c('v-overlay',{attrs:{"absolute":"","color":"white","opacity":"0.75"},model:{value:(_vm.showOverlay),callback:function ($$v) {_vm.showOverlay=$$v},expression:"showOverlay"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }